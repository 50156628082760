import React from "react"
import styled from "styled-components"
import GoogleMapReact from "google-map-react"

import Layout from "../../components/Layout"
import { Container, Title } from "../../components/atoms"
import { Sidebar } from "../../components/molecules"

const ContactLayout = styled(Container)`
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    @media (min-width: 768px) {
        display: flex;
        align-items: flex-start;
    }
    @media (min-width: 768px) {
        margin-top: 4em;
        margin-bottom: 4em;
    }
`

const ArticleContent = styled.article`
    @media (min-width: 768px) {
        flex: 0 0 60%;
        padding-right: 2em;
    }
    @media (min-width: 960px) {
        padding-right: 4em;
    }
`

const ContactSidebar = styled(Sidebar)`
    @media (min-width: 768px) {
        flex: 0 0 40%;
    }
    @media (max-width: 767px) {
        margin-top: 1.5em;
    }
`

const FormElement = styled.div`
    margin-bottom: 1.25em;
`

const Label = styled.label`
    display: block;
    font-weight: 600;
`

const InputDescription = styled.p`
    margin: 0.5em 0 1em;
`

const TextInput = styled.input`
    width: 100%;
    padding: 1em;
    border: 1px solid #d8d8d8;
`

const Select = styled.select`
    display: block;
    width: 100%;
    padding: 1em;
    border: 1px solid #d8d8d8;
`

const Textarea = styled.textarea`
    display: block;
    width: 100%;
    padding: 1em;
    border: 1px solid #d8d8d8;
`

const FormSubmit = styled.input`
    transition: all 0.25s ease;
    display: inline-block;
    border: 2px solid #ff6700;
    text-transform: uppercase;
    padding: 8px 12px;
    font-weight: 600;
    background-color: transparent;
    color: #ff6700;
    line-height: 1.6;
    cursor: pointer;
    &:hover {
        background-color: #ff6700;
        color: #ffffff;
    }
`

const Address = styled.p`
    margin: 0.5em 0 1em;
`

const Contact = () => {
    return (
        <Layout>
            <main>
                <ContactLayout forwardedAs="section" maxWidth="73.125em" gutter>
                    <ArticleContent>
                        <Title as="h1" marginBottom>Contact us</Title>
                        <p>
                            Donec rutrum congue leo eget malesuada. Donec
                            sollicitudin molestie malesuada. Quisque velit nisi,
                            pretium ut lacinia in, elementum id enim. Vivamus
                            magna justo, lacinia eget consectetur sed, convallis
                            at tellus. Donec rutrum congue leo eget malesuada.
                        </p>
                        <form
                            name="Contact"
                            method="POST"
                            action="/contact/thank-you"
                            netlify-honeypot="bot-field"
                            data-netlify="true"
                        >
                            <input
                                type="hidden"
                                name="form-name"
                                value="Contact"
                            />
                            <input type="hidden" name="bot-field" />
                            <FormElement>
                                <Label htmlFor="first_name">First name</Label>
                                <TextInput
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    placeholder="Enter your first name..."
                                />
                            </FormElement>
                            <FormElement>
                                <Label htmlFor="surname">Surname</Label>
                                <TextInput
                                    type="text"
                                    id="surname"
                                    name="surname"
                                    placeholder="Enter your surname..."
                                />
                            </FormElement>
                            <FormElement>
                                <Label htmlFor="team">Team</Label>
                                <InputDescription>
                                    If your enquiry relates to one of ours
                                    teams, please specify below.
                                </InputDescription>
                                <Select
                                    id="team"
                                    name="team"
                                    defaultValue="none"
                                >
                                    <option value="none">None</option>
                                    <option value="u8">Under 8s</option>
                                    <option value="u9">Under 9s</option>
                                    <option value="u10">Under 10s</option>
                                    <option value="u11">Under 11s</option>
                                    <option value="u12">Under 12s</option>
                                </Select>
                            </FormElement>
                            <FormElement>
                                <Label htmlFor="enquiry">Your enquiry</Label>
                                <Textarea
                                    id="enquiry"
                                    name="enquiry"
                                    cols="30"
                                    rows="10"
                                    placeholder="Enter your enquiry here..."
                                />
                            </FormElement>
                            <FormSubmit type="submit" value="Submit enquiry" />
                        </form>
                    </ArticleContent>
                    <ContactSidebar>
                        <Title as="h3" noMargin>Find us</Title>
                        <Address>
                            Thackeray's Lane,
                            <br />
                            Woodthorpe,
                            <br />
                            Nottingham
                        </Address>
                        <div style={{ height: "500px", width: "100%" }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key:
                                        "AIzaSyAsyb1EkMc3krRCqGOyb52jK23rJJSaoNw",
                                }}
                                defaultCenter={{
                                    lat: 52.993868,
                                    lng: -1.137122,
                                }}
                                defaultZoom={16}
                            />
                        </div>
                    </ContactSidebar>
                </ContactLayout>
            </main>
        </Layout>
    )
}

export default Contact